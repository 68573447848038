import api from "@/api";

export default {
  namespaced: true,

  state: {
    loaded: false,
    questions: [],
  },

  getters: {
    loaded(state) {
      return state.loaded;
    },

    questions(state) {
      return state.questions;
    }
  },

  mutations: {
    storeQuestions(state, payload) {
      for (let key in payload) {
        if (!payload.hasOwnProperty(key))
          continue;

        if (state.questions.some(f => f.id === payload[key].id))
          continue;

        state.questions.push(payload[key]);
      }
    },

    setLoaded(state, loaded) {
      state.loaded = loaded;
    }
  },

  actions: {
    async load({ state, commit }) {
      if (state.loaded)
        return;

      try {
        const faqs = await api.content.faqs.all();

        const questions = faqs.questions.map(faq => {
          return {
            id: faq.id,
            question: faq.question,
            answer: faq.answer,
            tags: faq.tags ? faq.tags.split(', ').map(t => t.trim().toLowerCase()) : []
          }
        });

        commit('storeQuestions', questions);
        commit('setLoaded', true);
      } catch (e) {
        console.error(e);
      }
    }
  }
}
