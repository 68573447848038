<template>
  <v-container>
    <v-row>
      <v-spacer />
      <v-col cols="12" sm="9" md="7">
        <h1 class="text-center">Presskits</h1>

        <p>
          These are all presskits available:
        </p>

        <v-skeleton-loader type="paragraph@3,article@3" :loading="kits.length === 0">
          <ul>
            <li v-for="kit in kits" :key="kit.id">
              <a :href="'#' + $options.filters.validId(kit.name)" @click.prevent="$vuetify.goTo('#' + $options.filters.validId(kit.name))">{{ kit.name }}</a>
            </li>
          </ul>

          <v-divider class="my-5" />

          <v-container fluid>
            <template v-for="(kit, i) in kits">
              <v-row :key="kit.id">
                <v-col>
                  <h2 :id="kit.name | validId">{{ kit.name }}</h2>

                  <v-md-preview v-if="kit.description" v-text="kit.description"></v-md-preview>

                  <v-carousel v-if="kit.media" height="250">
                    <v-carousel-item v-for="entry in kit.media" :key="entry.id">
                      <v-img v-if="entry.mime.indexOf('image/') === 0"
                             :lazy-src="$contentImage(entry, 'small').href"
                             :src="$contentImage(entry, 'large').href"
                             contain></v-img>
                    </v-carousel-item>
                  </v-carousel>

                  <div class="mt-3 float-right">
                    <v-btn @click="copyLinkToClipboard(kit)"
                           class="mr-2">
                      Share
                    </v-btn>
                    <v-btn v-if="kit.download !== null"
                           download
                           target="_blank"
                           :href="$contentImage(kit.download).href">
                      Download
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-divider class="my-5" v-if="i < kits.length - 1" />
            </template>
          </v-container>
        </v-skeleton-loader>
      </v-col>
      <v-spacer/>
    </v-row>

    <action-snackbar text="Link copied!"
                     action-text="Ok"
                     v-model="snackbar.shown"
                     :timeout="snackbar.timeout"
                     @clicked="snackbar.shown = false"/>
  </v-container>
</template>

<script>
import ActionSnackbar from "@/components/ActionSnackbar.vue";
import {mapGetters} from "vuex";

export default {
  components: {ActionSnackbar},

  async created() {
    await this.$store.dispatch('presskits/loadAll')
  },

  mounted() {
    let target = window.location.hash;
    if (target) {
      this.$vuetify.goTo(target);
    }
  },

  data() {
    return {
      snackbar: {
        shown: false,
        timeout: 5000,
      }
    }
  },

  computed: {
    ...mapGetters({
      kits: 'presskits/all'
    })
  },

  filters: {
    validId(name) {
      return name.toLowerCase().replace(/[\s]+/g, '_')
    }
  },

  methods: {
    async copyLinkToClipboard(kit) {
      let u = new URL(window.location.href);
      u.hash = this.$options.filters.validId(kit.name);
      await navigator.clipboard.writeText(u.href);

      this.snackbar.shown = true;
    }
  }
}
</script>
