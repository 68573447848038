import ApiClient from "@/helpers/api-client.js";
import api from "@/api";
import store from "@/store";

export const RESULT_TYPE = {
  ARTICLE: 'article',
  FAQ: 'faq',
  PRESSKIT: 'presskit'
};

export default {
  async combined(query) {
    let results = [];

    let articles = await this.articles(query);
    let presskits = await this.presskits(query);
    let faqs = await this.faqs(query);

    results.push(...articles);
    results.push(...presskits);
    results.push(...faqs);

    return results;
  },

  async articles(query) {
    let result = await this.search(api.content.qualify('articles'), query);

    return result.data.map(a => a.attributes).map(a => {
      return ({
        type: RESULT_TYPE.ARTICLE,
        title: a.title,
        description: a.summary,
        link: {
          name: 'article-detail',
          params: {
            article_id: a.id
          }
        },
        original: a
      });
    });
  },

  async presskits(query) {
    await store.dispatch('presskits/loadAll');

    let kits = store.getters['presskits/all'];

    return kits
      .filter(kit =>
        kit.name.toLowerCase().includes(query) ||
        kit.description.toLowerCase().includes(query)
      )
      .map(p => ({
        type: RESULT_TYPE.PRESSKIT,
        title: p.name,
        description: p.description,
        link: {
          name: 'press',
          query: {
            id: p.id
          }
        },
        original: p
      }));
  },

  async faqs(query) {
    if (!store.getters['faqs/loaded'])
      await store.dispatch('faqs/load');

    let questions = store.getters['faqs/questions'];

    return questions
      .filter(faq =>
        faq.question.toLowerCase().includes(query) ||
        faq.answer.toLowerCase().includes(query) ||
        faq.tags.some(t => t.includes(query))
      )
      .map(faq => ({
        type: RESULT_TYPE.FAQ,
        title: faq.question,
        description: faq.answer,
        link: {
          name: 'faq',
          query: {
            id: faq.id
          }
        },
        original: faq
      }));
  },

  async search(url, query) {
    url.searchParams.append('_q', encodeURIComponent(query));
    url.searchParams.append('_limit', '10'); // TODO: pagination of search results

    let response = await ApiClient.get(url.href);
    if (response.status === 429) {
      console.log('Too many requests.')

      return [];
    }

    if (response.status > 299)
      throw new Error("Invalid response code: " + response.status);

    return await response.json();
  }
}
